<template>
	<div
		class="collapse"
		:class="{
			active: isShowFilter,
			notempty: duration && localDuration.some(x => !!x) ||
				durationWork && localDurationWork.some(x => !!x) ||
				localBirthdayAfter ||
				localBirthdayBefore
		}"
	>
		<div
			class="collapse-button"
			@click="showFilters"
		>
			<div class="vacancies-filter-title">
				{{ titleFilter }}
			</div>
		</div>
		<div
			v-if="age"
			class="collapse-body"
		>
			<div class="cells">
				<div class="cell">
					<label class="field-wrap">
						<div class="field-default__label">от</div>
						<input
							v-model="localBirthdayBefore"
							type="number"
							class="field-default field-default--measure"
							maxlength="2"
							min="1"
						>
					</label>
				</div>
				<div class="cell">
					<label class="field-wrap">
						<div class="field-default__label">до</div>
						<input
							v-model="localBirthdayAfter"
							type="number"
							class="field-default field-default--measure"
							maxlength="2"
						>
					</label>
				</div>
			</div>
		</div>
		<div
			v-if="durationWork"
			class="collapse-body"
		>
			<div class="range-slider-item">
				<div class="slider-options">
					<span>Нет опыта</span>
					<span>До 1 года</span>
					<span>1 год</span>
					<span>2 года</span>
					<span>От 3 лет</span>
				</div>
				<Slider
					v-model="localDurationWork"
					:min="durationWork.min"
					:max="durationWork.max"
					:format="durationWork.format"
					:step="durationWork.step"
				/>
			</div>
		</div>
		<div
			v-if="duration"
			class="collapse-body"
		>
			<div class="range-slider-item duration">
				<Slider
					v-model="localDuration"
					:min="duration.min"
					:max="duration.max"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import Slider from '@vueform/slider/dist/slider.vue2.js';
import '@vueform/slider/themes/default.css';
export default {
	name: 'BaseSidebar',

	components: {
		Slider
	},

	props: {
		duration: {
			type: Object,
			default: null
		},
        durationWork: {
            type: Object,
            default: null
        },
        age: {
            type: Object,
            default: null
        },
        titleFilter: {
		    type: String,
            default: ''
        },
        value: {
            type: Array,
            default: null
        },
        valueBirthdayAfter: {
		    type: Number,
            default: null
        },
        valueBirthdayBefore: {
            type: Number,
            default: null
        },
        isShow: {
            type: Boolean,
            default: false
        },
	},

	data: () => ({
		isShowFilter: false,
		localDuration: [0, 1000],
        localDurationWork: [0, 80],
        localBirthdayBefore: null,
        localBirthdayAfter: null,
	}),
	watch: {
        age() {
            if(!this.age.value.length) {
                this.localBirthdayBefore = ''
                this.localBirthdayAfter = ''
            }
        },
        duration: {
            handler() {
                this.localDuration = this.duration.value
            },
            deep: true
        },
		localDuration() {
			this.$emit('setDuration', this.localDuration);
		},
        durationWork() {
            this.localDurationWork = this.durationWork.value
        },
        localDurationWork() {
          this.$emit('setDurationWork', this.localDurationWork);
        },
        localBirthdayBefore() {
          this.$emit('setBirthdayBefore', this.localBirthdayBefore);
        },
        localBirthdayAfter() {
            this.$emit('setBirthdayAfter', this.localBirthdayAfter);
        },
        isShow() {
			this.isShowFilter = this.isShow;
		}
	},
	mounted() {
        this.isShowFilter = this.isShow
        if(this.durationWork) {
            this.localDurationWork = this.value
        }
        if(this.valueBirthdayAfter) {
            this.localBirthdayAfter = this.valueBirthdayAfter
        }
        if(this.valueBirthdayBefore) {
            this.localBirthdayBefore = this.valueBirthdayBefore
        }
    },
    methods: {
        showFilters() {
            this.isShowFilter = !this.isShowFilter
            this.$emit('showFiltersDuration', this.isShowFilter);
        }
    },
};
</script>

<style lang="less">
.slider-tooltip {
	background: #ef722e !important;
	border-color: #ef722e !important;
}

.range-slider-item {
	padding-top: 40px;
}
.slider-horizontal .slider-tooltip {
    font-size: 10px;
}
.duration .slider-tooltip {
    font-size: 14px;
}
.range-slider-item {
    padding-top: 40px;
}
.slider-options {
    display: flex;
    justify-content: space-between;
    position: relative;
}
.slider-options span {
    position: absolute;
    bottom: 14px;
    transform: translate(-50%);
    display: block;
    font-size: 10px;
    background: #EF722E;
    padding: 2px;
    text-align: center;
    color: white;
    border-radius: 5px;
    border: 1px solid #EF722E;
    white-space: nowrap;
    min-width: 20px;
    &:nth-child(1) {
        left: 0;
        @media @mobile-end {
            left: 1%;
            &:before {
                left: 45% !important;
            }
        }
    }
    &:nth-child(2) {
        left: 25%;
    }
    &:nth-child(3) {
        left: 50%;
    }
    &:nth-child(4) {
        left: 75%;
    }
    &:nth-child(5) {
        left: 100%;
        @media @mobile-end {
            left: 99%;
            &:before {
                left: 60% !important;
            }
        }
    }
}
.slider-options span:before {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 50%;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-top-color: inherit;
    transform: translate(-50%)
}
</style>
