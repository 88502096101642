var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"collapse",class:{
		active: _vm.isShowFilter,
		notempty: _vm.duration && _vm.localDuration.some(x => !!x) ||
			_vm.durationWork && _vm.localDurationWork.some(x => !!x) ||
			_vm.localBirthdayAfter ||
			_vm.localBirthdayBefore
	}},[_c('div',{staticClass:"collapse-button",on:{"click":_vm.showFilters}},[_c('div',{staticClass:"vacancies-filter-title"},[_vm._v(" "+_vm._s(_vm.titleFilter)+" ")])]),(_vm.age)?_c('div',{staticClass:"collapse-body"},[_c('div',{staticClass:"cells"},[_c('div',{staticClass:"cell"},[_c('label',{staticClass:"field-wrap"},[_c('div',{staticClass:"field-default__label"},[_vm._v("от")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localBirthdayBefore),expression:"localBirthdayBefore"}],staticClass:"field-default field-default--measure",attrs:{"type":"number","maxlength":"2","min":"1"},domProps:{"value":(_vm.localBirthdayBefore)},on:{"input":function($event){if($event.target.composing)return;_vm.localBirthdayBefore=$event.target.value}}})])]),_c('div',{staticClass:"cell"},[_c('label',{staticClass:"field-wrap"},[_c('div',{staticClass:"field-default__label"},[_vm._v("до")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localBirthdayAfter),expression:"localBirthdayAfter"}],staticClass:"field-default field-default--measure",attrs:{"type":"number","maxlength":"2"},domProps:{"value":(_vm.localBirthdayAfter)},on:{"input":function($event){if($event.target.composing)return;_vm.localBirthdayAfter=$event.target.value}}})])])])]):_vm._e(),(_vm.durationWork)?_c('div',{staticClass:"collapse-body"},[_c('div',{staticClass:"range-slider-item"},[_vm._m(0),_c('Slider',{attrs:{"min":_vm.durationWork.min,"max":_vm.durationWork.max,"format":_vm.durationWork.format,"step":_vm.durationWork.step},model:{value:(_vm.localDurationWork),callback:function ($$v) {_vm.localDurationWork=$$v},expression:"localDurationWork"}})],1)]):_vm._e(),(_vm.duration)?_c('div',{staticClass:"collapse-body"},[_c('div',{staticClass:"range-slider-item duration"},[_c('Slider',{attrs:{"min":_vm.duration.min,"max":_vm.duration.max},model:{value:(_vm.localDuration),callback:function ($$v) {_vm.localDuration=$$v},expression:"localDuration"}})],1)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slider-options"},[_c('span',[_vm._v("Нет опыта")]),_c('span',[_vm._v("До 1 года")]),_c('span',[_vm._v("1 год")]),_c('span',[_vm._v("2 года")]),_c('span',[_vm._v("От 3 лет")])])
}]

export { render, staticRenderFns }