<template>
	<div
		v-if="filters"
		class="collapse"
		:class="{
			active: isShowFilters,
			notempty: localFilters.length || localTurbo
		}"
	>
		<div
			class="collapse-button"
			@click="showFilters"
		>
			<div class="vacancies-filter-title">
				{{ titleFilter }}
			</div>
		</div>
		<div
			v-if="filters"
			class="collapse-body"
		>
			<BaseCheckbox
				v-if="isShowTooltip"
				:id="'seen-turbo'"
				v-model="localTurbo"
				:is-active="false"
				is-check-mark
				:label="'Активный пользователь'"
				@input="changeTurbo"
			/>
			<BaseInput
				v-if="isShowSearch"
				v-model.trim="valueSearch"
				is-sidebar
				:label="`Введите название ${nameLabelSearchInput}`"
				@input="searchSlug"
			/>
			<div
				v-for="(filter, index) in shownFiltersList"
				:key="index"
				class="field-wrap sm"
			>
				<span
					class="vacancies-filter-number"
				>
					{{
						filter.number_of_courses
							? filter.number_of_courses
							: filter.information_course
								? filter.information_course.count
								: filter.count
					}}
				</span>
				<BaseCheckbox
					:id="filter.id ? filter.id : filter.name"
					v-model="localFilters"
					is-sidebar
					:is-show-tooltip="isShowTooltip"
					:count="filter.count"
					:label="
						isTypeEmployments
							? typeEmploymentFormat(filter.name)
							: filter.name
					"
					@input="changeValue"
				/>
			</div>
			<div
				v-if="filters.length > 10 && isLoadPart === false"
				class="hide-button"
			>
				<div class="field-wrap">
					<BaseButton
						is-border-bottom
						type="button"
						@clickButton="showAllFilters"
					>
						{{ isShowAllFilters ? "Свернуть" : "Показать все" }}
					</BaseButton>
				</div>
			</div>
			<div
				v-if="!isShowAllFilters && textMoreFilters"
				class="hide-button"
			>
				<div class="field-wrap">
					<BaseButton
						is-border-bottom
						type="button"
						@clickButton="showMoreNotNullSlug"
					>
						{{ textMoreFilters }}
					</BaseButton>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseCheckbox from '@/components/BaseComponents/BaseCheckbox.vue';
import BaseInput from '@/components/BaseComponents/BaseInput.vue';
import BaseButton from '@/components/BaseComponents/BaseButton.vue';

export default {
	components: {
		BaseCheckbox,
		BaseInput,
		BaseButton
	},
	props: {
		name: {
			type: String,
			default: ''
		},
		filters: {
			type: Array,
			default: () => []
		},
		activeFiltersLocalStorage: {
			type: Object,
			default: () => {}
		},
		titleFilter: {
			type: String,
			default: 'Заголовок фильтра'
		},
		textMoreFilters: {
			type: String,
			default: ''
		},
		isLoadPart: {
			type: Boolean,
			default: false
		},
		isTypeEmployments: {
			type: Boolean,
			default: false
		},
		isShowSearch: {
			type: Boolean,
			default: false
		},
		isShowTooltip: {
			type: Boolean,
			default: false
		},
		slugSearch: {
			type: String,
			default: ''
		},
		isClear: {
			type: Boolean,
			default: false
		},
		value: {
			type: Array,
			default: null
		},
		valueTurbo: {
			type: Boolean,
			default: false
		},
		isShow: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			isShowFilters: false,
			localFilters: [],
			isShowAllFilters: false,
			isKeyName: true,
			valueSearch: '',
			localTurbo: false
		};
	},
    computed: {
        activeFilters() {
            return this.activeFiltersLocalStorage
                ? this.activeFiltersLocalStorage[this.name].value
                : [];
        },
        nameLabelSearchInput() {
            return this.slugSearch === 'companies' ? 'компании' : 'города';
        },
        ...mapGetters('cnst', {
            employments: 'getEmployments'
        }),
        shownFiltersList() {
            if (!this.isLoadPart && !this.isShowAllFilters)
                return this.filters.slice(0, 10);
            return this.filters;
        }
    },
	watch: {
		valueTurbo() {
			this.localTurbo = this.valueTurbo;
		},
		activeFilters() {
			if (!this.activeFilters.length) {
				this.localFilters = [];
			} else {
				this.localFilters = this.activeFilters;				
			}
		},
		isShow() {
			this.isShowFilters = this.isShow;
		}
	},
	mounted() {
		this.isShowFilters = this.isShow;
		if (this.activeFilters.length > 0) {
			this.localFilters = this.activeFilters;
		}
		if (this.titleFilter === 'Направление') {
			this.isShowFilters = true;
            if (screen.width <= '1100') {
                this.isShowFilters = false
            }
		}
		if (this.value) {
			this.localFilters = this.value;
		}
		if (this.valueTurbo) {
			this.localTurbo = this.valueTurbo;
		}
	},
	methods: {
		showAllFilters() {
			this.isShowAllFilters = !this.isShowAllFilters;
		},
		changeValue() {
			this.$emit('setFilters', this.localFilters);
		},
		changeTurbo() {
			this.$emit('setTurbo', this.localTurbo);
		},
		showMoreNotNullSlug() {
			this.$emit('showMoreNotNullSlug');
		},
		typeEmploymentFormat(type) {
			return this.employments.find(x => x.slug === type).value;
		},
		searchSlug() {
			let slug = this.slugSearch;
			let search = this.valueSearch;
			this.$emit('searchSlug', { slug, search });
		},
		showFilters() {
			this.isShowFilters = !this.isShowFilters;
			this.$emit('showFiltersSidebars', this.isShowFilters);
		}
	}
};
</script>
<style lang="less">
.collapse {
	position: relative;
	padding-top: 18px;
	padding-bottom: 18px;
	&:after {
		content: "";
		display: block;
		position: absolute;
		bottom: 0;
		left: -30px;
		right: -30px;
		height: 1px;
		background: #dee4ed;
		@media @desktop-end {
		}
		@media @laptop-end {
		}
		@media @tablet-end {
			left: -18px;
			right: -18px;
		}
		@media @mobile-end {
			left: -15px;
			right: -15px;
		}
	}
	&.active {
		.collapse-button {
			&:before {
				.rotate(@deg: 180deg);
			}
		}
	}
}
.collapse-button {
	position: relative;
	padding: 0;
	.transition;
	&:before {
		content: "";
		display: block;
		position: absolute;
		top: 50%;
		right: 0;
		width: 16px;
		height: 10px;
		margin-top: -5px;
		background: url(../../../assets/img/icons/icon-select-dark.svg) center
			center no-repeat;
		-webkit-background-size: contain;
		background-size: contain;
		.transition;
	}
	&:hover {
		opacity: 0.7;
	}
}
.collapse-body {
	padding-top: 5px;
	padding-bottom: 15px;
}
.vacancies-filter-number {
	position: absolute;
	top: 50%;
	margin-top: 0;
	right: -13px;
	transform: translateY(-40%);
	text-align: right;
	font-size: 14px;
	color: #aaa;
	top: 50%;
	& + .checkbox-filter {
		padding-right: 40px;
	}
}
</style>
