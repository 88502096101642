<template>
	<div
		class="vacancies-sidebar"
		:class="{ notempty: isNotEmptyFilters() }"
	>
		<div class="vacancies-sidebar-header">
			<div class="cells">
				<div class="cell">
					<div class="vacancies-sidebar-title">
						Фильтры
					</div>
				</div>
				<div class="cell">
					<BaseButton
						is-border-bottom-none
						@clickButton="showFilters"
					>
						{{ isShowFilters ? "Свернуть" : "Показать" }} фильтры
					</BaseButton>
				</div>
			</div>
		</div>
		<div
			class="vacancies-sidebar-body"
			:class="{ active: isShowFilters }"
		>
			<form>
				<FilterSidebar
					v-if="direction"
					name="sections"
					title-filter="Направление"
					:filters="direction"
					:active-filters-local-storage="filters"
					:is-show="isShowDirection"
					@showFiltersSidebars="showDirection"
					@setFilters="setDirection"
				/>
				<FilterSidebar
					v-if="themes"
					title-filter="Тема"
					name="subsections"
					:filters="themes"
					:is-show="isShowThemes"
					:active-filters-local-storage="filters"
					@showFiltersSidebars="showThemes"
					@setFilters="setThemes"
				/>
				<FilterDuration
					v-if="duration"
					title-filter="Длительность, мин"
					name="duration"
					:duration="duration"
					:filters="duration"
					:is-show="isShowDuration"
					@showFiltersDuration="showDuration"
					@setDuration="setDuration"
				/>
				<FilterSidebar
					v-if="type"
					name="type"
					title-filter="Тип обучения"
					:filters="type"
					:active-filters-local-storage="filters"
					:is-show="isShowType"
					@showFiltersSidebars="showType"
					@setFilters="setType"
				/>
				<FilterSidebar
					v-if="cities"
					name="cities"
					:filters="cities"
					:active-filters-local-storage="filters"
					title-filter="Города"
					:is-show-search="isShowSearch"
					:text-more-filters="textMoreCities"
					:is-show="isShowCities"
					slug-search="cities"
					is-load-part
					@showFiltersSidebars="showFilterCities"
					@searchSlug="searchSlug"
					@setFilters="setCities"
					@showMoreNotNullSlug="showMoreNotNullSlug('cities')"
				/>
				<FilterSidebar
					v-if="education"
					name="education"
					:active-filters-local-storage="filters"
					title-filter="Уровень образования"
					:filters="education"
					:value="valueEducation"
					:is-show="isShowEducation"
					@setFilters="setEducation"
					@showFiltersSidebars="showFilterEducation"
				/>
				<FilterSidebar
					v-if="companies"
					:filters="companies"
					:active-filters-local-storage="filters"
					name="companies"
					title-filter="Компания"
					:is-show-search="isShowSearch"
					:is-show="isShowCompanies"
					:text-more-filters="textMoreCompanies"
					:value="valueCompanies"
					slug-search="companies"
					is-load-part
					@searchSlug="searchSlug"
					@setFilters="setCompanies"
					@showMoreNotNullSlug="showMoreNotNullSlug('companies')"
					@showFiltersSidebars="showFilterCompanies"
				/>
				<FilterSidebar
					v-if="activities"
					title-filter="Сфера деятельности"
					:filters="activities"
					:active-filters-local-storage="filters"
					name="activities"
					:is-show="isShowActivities"
					:value="valueActivities"
					@setFilters="setActivities"
					@showFiltersSidebars="showFilterActivities"
				/>
				<FilterSidebar
					v-if="specs"
					title-filter="Специализация"
					name="specs"
					:filters="specs"
					:is-show="isShowSpecs"
					:value="valueSpecs"
					:active-filters-local-storage="filters"
					@setFilters="setSpecializations"
					@showFiltersSidebars="showFilterSpecs"
				/>
				<FilterSidebar
					v-if="types"
					title-filter="Тип занятости"
					:name="employmentsType ? 'employmentsType' : 'types' "
					:filters="types"
					:is-show="isShowTypes"
					:value="valueTypes"
					is-type-employments
					:active-filters-local-storage="filters"
					@setFilters="setTypeEmployments"
					@showFiltersSidebars="showFilterTypes"
				/>
				<FilterDuration
					v-if="durationWork"
					title-filter="Опыт работы"
					name="durationWork"
					:duration-work="durationWork"
					:active-filters-local-storage="filters"
					:value="durationWork.value"
					:is-show="isShowDurationWork"
					@setDurationWork="setDurationWork"
					@showFiltersDuration="showFilterDurationWork"
				/>
				<FilterDuration
					v-if="age"
					title-filter="Возраст, лет"
					:age="age"
					:active-filters-local-storage="filters"
					name="age"
					:value-birthday-after="Number(valueBirthdayAfter)"
					:value-birthday-before="Number(valueBirthdayBefore)"
					:is-show="isShowAge"
					@setBirthdayBefore="setBirthdayBefore"
					@setBirthdayAfter="setBirthdayAfter"
					@showFiltersDuration="showFilterAge"
				/>
				<FilterSidebar
					v-if="seen"
					title-filter="Общее"
					name="seen"
					:filters="seen"
					is-show-tooltip
					:active-filters-local-storage="filters"
					:value-turbo="valueTurbo"
					:value="valueSeen"
					:is-show="isShowSeen"
					@setFilters="setSeen"
					@setTurbo="setTurbo"
					@showFiltersSidebars="showFilterSeen"
				/>
				<BaseButton
					class="button__filter-apply"
					orange
					max-width
					:style="'font-size: 16px;'"
					type="button"
					@clickButton="acceptFilter"
				>
					Применить
				</BaseButton>
				<BaseButton
					class="button__filter-clear"
					is-border-bottom
					type="button"
					@clickButton="clearFilter"
				>
					Очистить фильтры
				</BaseButton>
			</form>
		</div>
	</div>
</template>

<script>
import FilterSidebar from './FiltersSidebar.vue';
import FilterDuration from './FilterDuration.vue';
import BaseButton from '@/components/BaseComponents/BaseButton.vue';
import '@vueform/slider/themes/default.css';
export default {
	name: 'BaseSidebar',

	components: {
		BaseButton,
		FilterDuration,
		FilterSidebar
	},

	props: {
		direction: {
			type: Array,
			default: null
		},
		type: {
			type: Array,
			default: null
		},
		themes: {
			type: Array,
			default: null
		},
		duration: {
			type: Object,
			default: null
		},
		age: {
			type: Object,
			default: null
		},
		cities: {
			type: Array,
			default: null
		},
		education: {
			type: Array,
			default: null
		},
		textMoreCities: {
			type: String,
			default: ''
		},
		companies: {
			type: Array,
			default: null
		},
		textMoreCompanies: {
			type: String,
			default: ''
		},
		activities: {
			type: Array,
			default: null,
		},
		specs: {
			type: Array,
			default: null
		},
		types: {
			type: Array,
			default: null
		},
		seen: {
			type: Array,
			default: null
		},
		turbo: {
			type: Boolean,
			default: false
		},
		durationWork: {
			type: Object,
			default: null
		},
		isShowSearch: {
			type: Boolean,
			default: false
		},
		valueCities: {
			type: Array,
			default: null
		},
		valueCompanies: {
			type: Array,
			default: null
		},
		valueActivities: {
			type: Array,
			default: null
		},
		valueSpecs: {
			type: Array,
			default: null
		},
		valueTypes: {
			type: Array,
			default: null
		},
		valueEducation: {
			type: Array,
			default: null
		},
		valueBirthdayAfter: {
			type: Number,
			default: null
		},
		valueBirthdayBefore: {
			type: Number,
			default: null
		},
		valueTurbo: {
			type: Boolean,
			default: false
		},
		valueSeen: {
			type: Array,
			default: null
		},
		isShow: {
			type: Boolean,
			default: false
		},
		isShowCities: {
			type: Boolean,
			default: false
		},
		isShowCompanies: {
			type: Boolean,
			default: false
		},
		isShowActivities: {
			type: Boolean,
			default: false
		},
		isShowSpecs: {
			type: Boolean,
			default: false
		},
		isShowTypes: {
			type: Boolean,
			default: false
		},
		isShowEducation: {
			type: Boolean,
			default: false
		},
		isShowDuration: {
			type: Boolean,
			default: false
		},
		isShowDurationWork: {
			type: Boolean,
			default: false
		},
		isShowAge: {
			type: Boolean,
			default: false
		},
		isShowSeen: {
			type: Boolean,
			default: false
		},

		isShowDirection: {
			type: Boolean,
			default: false
		},
		isShowType: {
			type: Boolean,
			default: false
		},

		isShowThemes: {
			type: Boolean,
			default: false
		},

		filters: {
			type: Object,
			default: () => {}
		},
		employmentsType: {
			type: Boolean,
			default: false
		}
	},

	data: () => ({
		isShowFilters: false,
		localDirection: [],
		localType: [],
		localThemes: [],
		localDuration: [0, 1000],
		localCities: [],
		searchCities: '',
		localEducation: [],
		localCompanies: [],
		searchCompanies: '',
		localActivities: [],
		localSpecs: [],
		localTypes: [],
		localDurationWork: [],
		localBirthdayBefore: null,
		localBirthdayAfter: null,
		localSeen: [],
		localTurbo: false
	}),
	mounted() {
		if(this.filters.type)  {
			this.localType = this.filters.type.value
		}
		if (this.filters.cities) {
			this.localCities = this.filters.cities.value;
		}
		if (this.filters.education) {
			this.localEducation = this.filters.education.value;
		}
		if (this.filters.companies) {
			this.localCompanies = this.filters.companies.value;
		}
		if (this.filters.activities) {
			this.localActivities = this.filters.activities.value;
		}
		if (this.filters.specs) {
			this.localSpecs = this.filters.specs.value;
		}
		if (this.filters.types) {
			this.localTypes = this.filters.types.value;
		}
		if(this.filters.durationWork) {
			this.localDurationWork = this.durationWork
		}
		if(this.filters.seen) {
			this.localSeen = this.filters.seen.value
		}
		if(this.filters.age) {
			this.localBirthdayBefore = this.filters.age.value[0]
			this.localBirthdayAfter = this.filters.age.value[1]
		}
		// this.localTurbo: false
		this.isShowFilters = this.isShow;
	},

	methods: {
		isNotEmptyFilters() {
			return (
				this.localCities.length ||
				this.localEducation ||
				this.localCompanies.length ||
				this.localActivities.length ||
				this.localSpecs.length ||
				this.localTypes.length
			);
		},
		setDirection(direction) {
			this.localDirection = direction;
		},
		setType(type) {
			this.localType = type;
		},
		setThemes(themes) {
			this.localThemes = themes;
		},
		setDuration(duration) {
			this.localDuration = duration;
		},
		setDurationWork(durationWork) {
			this.localDurationWork = durationWork;
		},
		setBirthdayBefore(birthdayBefore) {
			this.localBirthdayBefore = birthdayBefore;
		},
		setBirthdayAfter(birthdayAfter) {
			this.localBirthdayAfter = birthdayAfter;
		},
		setCities(cities) {
			this.localCities = cities;
		},
		setEducation(education) {
			this.localEducation = education;
		},
		setCompanies(companies) {
			this.localCompanies = companies;
		},
		setActivities(activities) {
			this.localActivities = activities;
		},
		setSpecializations(specializations) {
			this.localSpecs = specializations;
		},
		setTypeEmployments(employments) {
			this.localTypes = employments;
		},
		setSeen(seen) {
			this.localSeen = seen;
		},
		setTurbo(turbo) {
			this.localTurbo = turbo;
		},
		acceptFilter() {
            this.$scrollTo(document.querySelector('.vacancies-sidebar'))
			this.$emit('changeParametersFilters', {
				localDirection: this.localDirection,
				localThemes: this.localThemes,
				localDuration: this.localDuration,
				localCities: this.localCities,
				localEducation: this.localEducation,
				localCompanies: this.localCompanies,
				localActivities: this.localActivities,
				localSpecs: this.localSpecs,
				localTypes: this.localTypes,
				localType: this.localType,
				localDurationWork: this.localDurationWork,
				localBirthdayBefore: this.localBirthdayBefore,
				localBirthdayAfter: this.localBirthdayAfter,
				localTurbo: this.localTurbo,
				localSeen: this.localSeen
			});
		},
		clearFilter() {
			this.$scrollTo(document.querySelector('header.header-role'))
			this.localDirection = [];
			this.localType = [];
			this.localThemes = [];
			this.localDuration = [100, 1000];
			this.localCities = [];
			this.searchCities = '';
			this.localEducation = [];
			this.localCompanies = [];
			this.searchCompanies = '';
			this.localActivities = [];
			this.localSpecs = [];
			this.localTypes = [];
			this.localDurationWork = [0, 80];
			this.localBirthdayBefore = '';
			this.localBirthdayAfter = '';
			this.localSeen = [];
			this.localTurbo = false;
			this.$emit('clearFilter', {
				localDirection: this.localDirection,
				localType: this.localType,
				localThemes: this.localThemes,
				localDuration: this.localDuration,
				localCities: this.localCities,
				localEducation: this.localEducation,
				localCompanies: this.localCompanies,
				localActivities: this.localActivities,
				localSpecs: this.localSpecs,
				localTypes: this.localTypes,
				localDurationWork: this.localDurationWork,
				localBirthdayBefore: this.localBirthdayBefore,
				localBirthdayAfter: this.localBirthdayAfter,
				localTurbo: this.localTurbo,
				localSeen: this.localSeen
			});
		},
		searchSlug(params) {
			this.$emit('searchSlug', params);
		},
		showMoreNotNullSlug(slug) {
			this.$emit('showMoreNotNullSlug', slug);
		},
		showFilters() {
			this.isShowFilters = !this.isShowFilters;
			this.$emit('showFilters', this.isShowFilters);
		},
		showDirection(isShow) {
			this.$emit('showFilterDirection', isShow);
		},
		showType(isShow) {
			this.$emit('showFilterType', isShow);
		},
		showDuration(isShow) {
			this.$emit('showFilterDuration', isShow);
		},
		showThemes(isShow) {
			this.$emit('showFilterThemes', isShow);
		},
		showFilterCities(isShow) {
			this.$emit('showFilterCities', isShow);
		},
		showFilterCompanies(isShow) {
			this.$emit('showFilterCompanies', isShow);
		},
		showFilterActivities(isShow) {
			this.$emit('showFilterActivities', isShow);
		},
		showFilterSpecs(isShow) {
			this.$emit('showFilterSpecs', isShow);
		},
		showFilterTypes(isShow) {
			this.$emit('showFilterTypes', isShow);
		},
		showFilterEducation(isShow) {
			this.$emit('showFilterEducation', isShow);
		},
		showFilterDurationWork(isShow) {
			this.$emit('showFilterDurationWork', isShow);
		},
		showFilterAge(isShow) {
			this.$emit('showFilterAge', isShow);
		},
		showFilterSeen(isShow) {
			this.$emit('showFilterSeen', isShow);
		}
	}
};
</script>

<style lang="less" scoped>
@import "../../../assets/styles/pages/courses";

.button {
	@media (max-width: 374px) {
		&__filter-clear {
			margin-bottom: 7px;
		}
	}

	&__filter-apply {
		margin: 35px auto 12px;
	}
}
.sidebar {
	padding: 5px 30px 25px;
	background: @color-light-blue;
	@media @tablet-end {
		margin-bottom: 40px;
		padding-top: 25px;
	}
	@media @mobile-end {
		padding: 18px 18px 18px 22px;
	}
	@media @oldy-end {
		padding: 15px 18px;
	}
	&.active {
		&.notempty {
			.sidebar__title {
				&:after {
					opacity: 0;
				}
			}
		}
	}
	&.notempty {
		.sidebar__title {
			&:after {
				opacity: 1;
			}
		}
	}
	.field {
		&:not(:placeholder-shown) {
			& + .label {
				opacity: 0;
				top: 12px;
			}
		}
	}
	.checkbox-filter {
		display: block;
		padding-top: 6px;
		font-size: 15px;
	}
	&__title {
		padding-left: 35px;
		.font-title;
		font-weight: 700;
		@media @mobile-end {
		}
		@media @oldy-end {
			padding-left: 29px;
		}
		&:before {
			content: "";
			display: block;
			position: absolute;
			top: 7px;
			left: 0;
			width: 18px;
			height: 11px;
			background: url("../../../assets/img/icons/icon-filter.png") center
				center no-repeat;
		}
		&:after {
			opacity: 0;
			content: "";
			display: block;
			position: absolute;
			top: 4px;
			left: 100%;
			width: 7px;
			height: 7px;
			margin-left: 6px;
			border-radius: 200px;
			background: @color-main;
			.transition;
		}
		&.notempty {
			&:after {
				opacity: 1;
			}
		}
	}
	&__body {
		@media @laptop-start {
			display: block !important;
		}
		@media @tablet-end {
			display: none;
			padding-top: 20px;
			&.active {
				display: block;
			}
		}
	}
	&__header {
		display: none;
		@media @tablet-end {
			display: block;
		}
		.cell {
			vertical-align: middle;
			&:nth-child(1) {
				width: 55%;
			}
			&:nth-child(2) {
				width: 45%;
				text-align: right;
			}
		}
	}
}
</style>
